import React, { useContext, useMemo } from 'react';
import { EventsGrid } from '@stereoagency/ui';
import { useQuery } from 'urql';
import flush from 'just-flush';
import { DateTime } from 'luxon';
import useTranslation from 'next-translate/useTranslation';
import { FindAllEventSummariesQueryDocument } from '@/gql/client/graphql';
import { useFragment } from '@/gql';
import {
  EventSummaryFieldsFragment,
} from '@/gql/documents/fragments';
import { useCallToActions, useLangCodeFilter } from '@/hooks';
import { LinesContext } from '@/components/LinesContext';

type Props = {
  exclude?: number[]
  hideIfEmpty?: boolean
  backgroundColor?: 'default' | 'offdefault'
  data: {
    label?: string
    title: string
    callToActions?: number[]
    limit?: number
  }
};

export default function NvisoEventsGrid({
  exclude,
  hideIfEmpty,
  backgroundColor,
  data: {
    label, title, callToActions, limit = 15,
  },
}: Props) {
  const langCode = useLangCodeFilter();
  const { enableLines } = useContext(LinesContext);
  const { t } = useTranslation();
  const callToActionObjects = useCallToActions(callToActions ?? [], false);
  const today = useMemo(() => DateTime.now().toUTC().toISODate(), []);
  const [{ data: eventSummariesQueryData }] = useQuery({
    query: FindAllEventSummariesQueryDocument,
    variables: {
      first: limit + (exclude?.length ?? 0),
      afterEventDatetime: today,
      lang: langCode,
    },
  });
  const eventSummaries = useFragment(EventSummaryFieldsFragment, flush(eventSummariesQueryData?.events?.nodes ?? []));
  const eventSummariesSlice = useMemo(() => {
    if (exclude?.length) {
      return eventSummaries
        .filter((event) => !exclude.includes(event.databaseId))
        .slice(0, limit);
    }
    return eventSummaries;
  }, [eventSummaries, exclude, limit]);

  if (eventSummaries.length === 0 && hideIfEmpty) {
    return null;
  }

  return (
    <EventsGrid
      backgroundColor={backgroundColor}
      enableLines={enableLines}
      label={label}
      title={title}
      callToActions={callToActionObjects}
      events={flush(eventSummariesSlice.map((event) => ({
        label: event.cptEventCustomFields?.isOnline
          ? t('global:online')
          : event.cptEventCustomFields?.country ? t(`countries:${event.cptEventCustomFields?.country}`) : undefined,
        title: event.title ?? '',
        pretitle: event.cptEventCustomFields?.datetime
          ? DateTime.fromISO(event.cptEventCustomFields?.datetime).toFormat('d.M.yyyy')
          : undefined,
        callToAction: {
          label: t('global:read-more'),
          href: event.uri ?? '/',
        },
      })))}
    />
  );
}
